<template>
  <div class="d-flex flex-column flex-root h-100" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page" id="content-body">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader
          v-bind:breadcrumbs="breadcrumbs"
          v-bind:title="pageTitle"
        ></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
          style="background: #fff"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <v-app>
              <!-- Error || Success Snackbar Start -->
              <div class="custom-alert-snackbar">
                <template v-for="(data, index) in errors">
                  <v-alert
                    v-model="data.model"
                    :key="'error-' + index"
                    type="error"
                    dismissible
                    elevation="2"
                    border="left"
                    transition="scroll-y-transition"
                  >
                    <span v-html="data.message"></span>
                  </v-alert>
                </template>
                <template v-for="(data, index) in success">
                  <v-alert
                    v-model="data.model"
                    :key="'success-' + index"
                    type="success"
                    dismissible
                    elevation="2"
                    border="left"
                    transition="scroll-y-transition"
                  >
                    <span v-html="data.message"></span>
                  </v-alert>
                </template>
              </div>
              <!-- Error || Success Snackbar End -->
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </v-app>
          </div>
        </div>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>    
    <KTScrollBottom></KTScrollBottom>    
    <div v-if="false && globalLoading" class="global-loader text">      
      <div class="text-center">
        <img alt="brang-logo" :src="loaderLogo" width="100" />
        <br>
        <br>
        <div>
          <span class="text-black font-weight-500 font-size-h1">Please wait...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style type="text/css">
  .global-loader {    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 68%); /* Adjust opacity or color as needed */
    z-index: 9999; /* Ensure it's on top of other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .global-loader .global-loader-content {
    color: #00bcd4;
  }
</style>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import KTScrollBottom from "@/view/layout/extras/ScrollBottom";
import Loader from "@/view/content/Loader.vue";
import {
  ErrorEventBus,
  SuccessEventBus,
  ClearEventBus,
} from "@/core/lib/message.lib";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTScrollTop,
    KTScrollBottom,
    Loader,
  },
  data() {
    return {
      errors: [],
      success: [],
      timeout: null,
      timeoutLimit: 5000,
      isAuthenticated: false,
      timeInterval: null,
    };
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  methods: {
    UserAuthentication() {
      const _this = this;
      // check if current user is authenticated
      try {
        JwtService.isAuthenticated()
          .then((response) => {
            _this.isAuthenticated = response;
            if (!response) {
              _this.$router.push({ name: "admin.login" });
            }
          })
          .catch(() => {
            _this.$router.push({ name: "admin.login" });
          });

        JwtService.isEngineer()
          .then((response) => {
            if (response) {
              _this.$router.push({ name: "admin.login" });
            }
          })
          .catch(() => {
            _this.$router.push({ name: "admin.login" });
          });
      } catch (error) {
        console.error(error);
        _this.$router.push({ name: "admin.login" });
      }
    },
  },
  mounted() {
    const _this = this;

    _this.UserAuthentication();
    clearInterval(_this.timeInterval);
    _this.timeInterval = setInterval(function () {
      _this.UserAuthentication();
    }, 5000);

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      _this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    SuccessEventBus.$off("update:success");
    ErrorEventBus.$off("update:error");
    ClearEventBus.$off("clear:success");
    ClearEventBus.$off("clear:error");
  },
  created() {
    const _this = this;

    SuccessEventBus.$on("update:success", (success) => {
      _this.success.push({ message: success, model: true });
      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(() => {
        for (let i = 0; i < _this.success.length; i++) {
          _this.success[i].model = false;
        }
      }, _this.timeoutLimit);
    });

    ErrorEventBus.$on("update:error", (error) => {
      if (_this.lodash.isArray(error)) {
        _this.errors = _this.lodash.concat(
          _this.errors,
          error.map((row) => {
            return { message: row, model: true };
          })
        );
      } else {
        _this.errors.push({ message: error, model: true });
      }
      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(() => {
        for (let i = 0; i < _this.errors.length; i++) {
          _this.errors[i].model = false;
        }
      }, _this.timeoutLimit);
    });

    ClearEventBus.$on("clear:success", () => {
      _this.success = new Array();
    });

    ClearEventBus.$on("clear:error", () => {
      _this.errors = new Array();
    });
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle", "layoutConfig", "globalLoading"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.VUE_APP_BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
