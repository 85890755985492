<template>
  <!-- begin::Scrollbottom -->
  <div class="scrollbottom" @click="scrollToBottom">
    <span class="svg-icon">
      <inline-svg :src="$assetURL('media/svg/icons/Navigation/Down-2.svg')" />
    </span>
  </div>
  <!-- end::Scrollbottom -->
</template>

<script>
import KTLayoutScrollbottom from "@/assets/js/layout/extended/scrolltop.js";

export default {
  name: "KTScrollBottom",
  methods:{
    scrollToBottom(){
      window.scrollTo({
        top:document.body.scrollHeight,
        behavior:'smooth'
      })
      // window.scrollTo(0, document.body.scrollHeight);
    }
  },
  mounted() {
    // Init Scrollbottom
    KTLayoutScrollbottom.init(this.$refs["kt_scrollbottom"]);
  },
};
</script>
